import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Works from "../components/works"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const B2B = () => (
  <Layout hero={false}>
    <SEO title="Nytt spännande kaffe till kontoret - Varje månad" />

    <Row>
      <Col>
        <h1 className="mt-4 mb-3">
          Nytt spännande kaffe till kontoret - Varje månad
        </h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={8}>
        <h2>För dig som vill skämma bort dina medarbetare</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu felis
          bibendum ut tristique et egestas quis ipsum suspendisse. Turpis
          tincidunt id aliquet risus feugiat in ante. Placerat in egestas erat
          imperdiet sed euismod nisi porta. Pharetra et ultrices neque ornare
          aenean. Urna duis convallis convallis tellus id interdum. Id leo in
          vitae turpis. Pellentesque dignissim enim sit amet venenatis. Amet
          nulla facilisi morbi tempus iaculis urna. Sem fringilla ut morbi
          tincidunt.
        </p>

        <h3>Fin fredag med finkaffe</h3>
        <p>
          Sagittis purus sit amet volutpat. In fermentum et sollicitudin ac orci
          phasellus egestas. Posuere sollicitudin aliquam ultrices sagittis orci
          a scelerisque purus. Porttitor massa id neque aliquam. Id neque
          aliquam vestibulum morbi blandit cursus risus at ultrices. Non nisi
          est sit amet facilisis magna etiam. Ut etiam sit amet nisl purus in
          mollis nunc sed. Suscipit tellus mauris a diam maecenas sed enim.
          Aenean et tortor at risus viverra adipiscing at in tellus. Risus at
          ultrices mi tempus imperdiet nulla malesuada. Sit amet est placerat in
          egestas erat. Id interdum velit laoreet id. Et ultrices neque ornare
          aenean euismod elementum. Elementum tempus egestas sed sed risus
          pretium quam.
        </p>

        <p>
          Dolor purus non enim praesent. Eros in cursus turpis massa tincidunt
          dui ut ornare. Ac auctor augue mauris augue neque gravida in. Nec
          feugiat in fermentum posuere urna nec tincidunt. Faucibus purus in
          massa tempor nec feugiat. Dui faucibus in ornare quam viverra orci.
          Nulla facilisi cras fermentum odio. Porta nibh venenatis cras sed
          felis. Mattis molestie a iaculis at erat pellentesque adipiscing.
          Augue mauris augue neque gravida in fermentum et sollicitudin. In hac
          habitasse platea dictumst vestibulum rhoncus est.
        </p>

        <p>
          Luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor.
          Nec nam aliquam sem et. Ornare arcu odio ut sem nulla pharetra diam
          sit. Risus ultricies tristique nulla aliquet enim tortor. Ipsum
          consequat nisl vel pretium lectus quam id. Lectus mauris ultrices eros
          in. Neque volutpat ac tincidunt vitae. Tellus integer feugiat
          scelerisque varius morbi enim. Neque ornare aenean euismod elementum
          nisi. Proin nibh nisl condimentum id venenatis a condimentum vitae
          sapien. Lorem sed risus ultricies tristique nulla.
        </p>

        <p>
          Lectus arcu bibendum at varius vel pharetra vel turpis nunc. Est sit
          amet facilisis magna etiam tempor orci eu lobortis. Diam ut venenatis
          tellus in metus vulputate eu. Integer quis auctor elit sed vulputate
          mi sit amet. Vulputate eu scelerisque felis imperdiet proin fermentum
          leo. Elit at imperdiet dui accumsan sit. Velit euismod in pellentesque
          massa. Malesuada bibendum arcu vitae elementum curabitur vitae nunc
          sed velit. Senectus et netus et malesuada fames ac turpis egestas.
          Integer vitae justo eget magna fermentum. Id aliquet lectus proin nibh
          nisl condimentum id. Aliquet risus feugiat in ante metus dictum at.
          Enim lobortis scelerisque fermentum dui faucibus in ornare quam.
          Fermentum iaculis eu non diam phasellus. Rhoncus urna neque viverra
          justo nec ultrices dui. Viverra nibh cras pulvinar mattis nunc sed
          blandit libero volutpat. Morbi tristique senectus et netus. Sed
          ullamcorper morbi tincidunt ornare massa eget egestas purus.
        </p>
      </Col>
    </Row>
    <Works />
  </Layout>
)

export default B2B
