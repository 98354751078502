import React from "react"
import Jumbotron from "react-bootstrap/Jumbotron"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Works = () => (
  <>
    <hr className="d-block d-sm-none" />
    <Jumbotron>
      <Row>
        <Col>
          <h1>Så funkar Kaffero!</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Varje månad väljer vi ut en exklusiv böna eller blandning av yttersta kvalitet. Våra bönor kommer från jordens alla hörn och produceras utav några av världens främsta kaffeproducenter.
          </p>
        </Col>
        <Col>
          <p>
            Vårt kaffe är alltid nyrostat och om du så önskar även nymalet. Vi arbetar med Sveriges främsta kafferosterier.
          </p>
        </Col>
        <Col>
          <p>
            Kaffet levereras direkt hem i brevlådan en gång varje månad. För dig är det bara att mala eller brygga direkt och njuta av en utsökt kopp kaffe.
          </p>
        </Col>
      </Row>
    </Jumbotron>
  </>
)

export default Works
